import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { MyContext } from "../data/contextapi";
import InfoIcon from "./InfoIcon";
import ColorSelector from "../../ColorSelector";

const stalKleur = [
  {
    id: "",
    title: "RAL 9005 fijn structuur",
    imgUrl: "./images/doortypes/RAL 9005 fijn structuur.png",
  },
  {
    id: "",
    title: "RAL 9004 fijn structuur",
    imgUrl: "./images/doortypes/RAL 9004 fijn structuur.png",
  },
  {
    id: "",
    title: "RAL 7021 structuur",
    imgUrl: "./images/doortypes/RAL 7021 structuur.png",
  },
  {
    id: "",
    title: "RAL 7016 structuur",
    imgUrl: "./images/doortypes/RAL 7016 structuur.png",
  },
  {
    id: "",
    title: "RAL 9010 structuur",
    imgUrl: "./images/doortypes/RAL 9010 structuur.png",
  },
  {
    id: "",
    title: "RAL 9016 structuur",
    imgUrl: "./images/doortypes/RAL 9016 structuur.png",
  },
  {
    id: "",
    title: "Anodic brown",
    imgUrl: "./images/doortypes/Anodic brown.png",
  },
  {
    id: "",
    title: "Sterling",
    imgUrl: "./images/doortypes/Sterling.png",
  },
  {
    id: "",
    title: "Nobble bronze",
    imgUrl: "./images/doortypes/Nobble bronze.png",
  },
  {
    id: "",
    title: "Halo 1036",
    imgUrl: "./images/doortypes/Halo 1036.png",
  },
  {
    id: "",
    title: "Halo 1037",
    imgUrl: "./images/doortypes/Halo 1037.png",
  },
  {
    id: "",
    title: "Anodic bronze",
    imgUrl: "./images/doortypes/Anodic_bronze.png",
  },
];

export default function Design() {
  const {
    settype,
    type,
    setStep,
    door,
    setdoor,
    frameSize,
    setFrameSize,
    glassType,
    setGlassType,
    frameType,
    setFrameType,
    handle,
    setHandle,
    attachDesign,
    setAttachDesign,
    setColorPickerOpened,
    customFrameType,
    setCustomFrameType,
    setCompositionImage,
  } = useContext(MyContext);

  const handleChange = (e) => {
    const { files } = e.target;
    console.log(files[0]);
    setAttachDesign(files[0]);
  };

  return (
    <>
      <div style={{ width: "100%", position: "relative" }}>
        <div
          style={{
            marginLeft: "5%",
            paddingTop: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "bolder",
            fontSize: "normal",
          }}
        >
          <InfoIcon title={"Design"} />
          {/* <div
            style={{
              backgroundColor: "white",
              borderRadius: "20px",
              width: "auto",
              padding: "2px 10px",
              margin: "0 0 0 10px",
            }}
          >
            <span
              className="body-txt"
              style={{}}
              onClick={() => {
                setStep("door");
              }}
            >
              &euro; 400,00
            </span>
          </div> */}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderBottom: "1px solid #d1d1d1",
        }}
      >
        <div
          className="door-content-2-1"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridTemplateRows: "repeat(6, 1fr)",
            gridColumnGap: "10px",
            gridRowGap: "10px",
            margin: "5%",
            maxWidth: "90%",
          }}
        >
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("george");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/1%20panel.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "george" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "george"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "george"}
                  readOnly
                  onChange={() => setdoor("george")}
                />
              </div>
              <img
                src="./images/doortypes/1 panel.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "george" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                1 paneel
              </span>
            </div>
          </div>
          {/* <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("govert");
            }}
          >
            <div
              style={{
                border:
                  door === "govert" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "govert"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "govert"}
                  readOnly
                  onChange={() => setdoor("govert")}
                />
              </div>
              <img
                src="./images/doortypes/govert.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "govert" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Govert
              </span>
            </div>
          </div> */}
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("2panel");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/2%20panel.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "2panel" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "2panel"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "2panel"}
                  readOnly
                  onChange={() => {
                    setdoor("2panel");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/2%20panel.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/2 panel.png"
                style={{ maxWidth: "100%", maxHeight: "100%", width: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "2panel" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                2 panelen
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("3panel");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/3%20panel.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "3panel" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "3panel"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "/3panel"}
                  readOnly
                  onChange={() => {
                    setdoor("3panel");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/3%20panel.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/3 panel.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "3panel" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                3 panelen
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("4panel");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/4%20panel.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "4panel" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "4panel"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "4panel"}
                  readOnly
                  onChange={() => {
                    setdoor("4panel");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/4%20panel.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/4 panel.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "4panel" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                4 panelen
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("3pannel");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/3-pannel.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "3pannel" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "3pannel"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "3pannel"}
                  readOnly
                  onChange={() => {
                    setdoor("3pannel");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/3-pannel.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/3-pannel.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "dt6" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                3 panelen ongelijk plus
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("annelot");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/annelot.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "annelot" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "annelot"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "annelot"}
                  readOnly
                  onChange={() => {
                    setdoor("annelot");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/annelot.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/annelot.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "annelot" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Annelot
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("notaris");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/dt6.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "notaris" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "notaris"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "notaris"}
                  readOnly
                  onChange={() => {
                    setdoor("notaris");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/dt6.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/dt6.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "notaris" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Notaris
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("boerderij");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/boerderij.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "boerderij"
                    ? "2px solid black"
                    : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "boerderij"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "boerderij"}
                  readOnly
                  onChange={() => {
                    setdoor("boerderij");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/boerderij.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/boerderij.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "boerderij" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Boerderij
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("herenhuis");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/herenhuis.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "herenhuis"
                    ? "2px solid black"
                    : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "herenhuis"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "herenhuis"}
                  readOnly
                  onChange={() => {
                    setdoor("herenhuis");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/herenhuis.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/herenhuis.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "herenhuis" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Herenhuis
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("rond");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/round.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "rond" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "rond"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "rond"}
                  readOnly
                  onChange={() => {
                    setdoor("rond");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/round.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/round.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "rond" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Rond
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("rondPlus");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/dt8.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "rondPlus" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "rondPlus"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "rondPlus"}
                  readOnly
                  onChange={() => {
                    setdoor("rondPlus");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/dt8.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/dt8.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "rondPlus" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Rond Plus
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("low-deep");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/low-deep.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "low-deep" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "low-deep"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "low-deep"}
                  readOnly
                  onChange={() => {
                    setdoor("low-deep");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/low-deep.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/low-deep.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "low-deep" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Low Deep
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("rivera");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/dt10.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "rivera" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "rivera"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "rivera"}
                  readOnly
                  onChange={() => {
                    setdoor("rivera");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/dt10.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/dt10.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "rivera" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Rivera
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("porto");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/dt9.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "porto" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "porto"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "porto"}
                  readOnly
                  onChange={() => {
                    setdoor("porto");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/dt9.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/dt9.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "porto" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Porto
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("toog");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/toog.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "toog" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "toog"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "toog"}
                  readOnly
                  onChange={() => {
                    setdoor("toog");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/toog.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/toog.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "toog" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Toog
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("toogPlus");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/dt9-right.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "toogPlus" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "toogPlus"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "toogPlus"}
                  readOnly
                  onChange={() => {
                    setdoor("toogPlus");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/dt9-right.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/dt9-right.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "toogPlus" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Toog plus
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("boender");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/dt12.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "boender" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "boender"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "boender"}
                  readOnly
                  onChange={() => {
                    setdoor("boender");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/dt12.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/dt12.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "boender" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Boender
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("contempera");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/contempera.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "contempera"
                    ? "2px solid black"
                    : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "contempera"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "contempera"}
                  readOnly
                  onChange={() => {
                    setdoor("contempera");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/contempera.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/contempera.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "contempera" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Contempera
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("fabric");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/fabric.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "fabric" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "fabric"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "fabric"}
                  readOnly
                  onChange={() => {
                    setdoor("fabric");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/fabric.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/fabric.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "fabric" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Fabric
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("dt13");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/dt13.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "dt13" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "dt13"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "dt13"}
                  readOnly
                  onChange={() => {
                    setdoor("dt13");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/dt13.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/dt13.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "dt13" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Hoogh
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("larino");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/estonia.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "larino" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "larino"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "larino"}
                  readOnly
                  onChange={() => {
                    setdoor("larino");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/estonia.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/estonia.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "estonia" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Larino
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("dt11");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/dt11.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "dt11" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "dt11"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "dt11"}
                  readOnly
                  onChange={() => {
                    setdoor("dt11");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/dt11.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/dt11.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "dt11" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Mexico
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("parallel");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/dt11.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "parallel" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "parallel"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "parallel"}
                  readOnly
                  onChange={() => {
                    setdoor("parallel");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/dt5.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/dt5.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "parallel" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Parallel
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("grandma");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/grandma.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "grandma" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "grandma"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "grandma"}
                  readOnly
                  onChange={() => {
                    setdoor("grandma");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/grandma.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/grandma.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "grandma" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Grandma
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("kasteel");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/kasteel.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "kasteel" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "kasteel"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "kasteel"}
                  readOnly
                  onChange={() => {
                    setdoor("kasteel");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/kasteel.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/kasteel.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "kasteel" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Kasteel
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("kathedraal");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/kathedraal.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "kathedraal"
                    ? "2px solid black"
                    : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "kathedraal"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "kathedraal"}
                  readOnly
                  onChange={() => {
                    setdoor("kathedraal");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/kathedraal.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/kathedraal.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "kathedraal" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Kathedraal
              </span>
            </div>
          </div>

          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("the-judge");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/the-judge.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "the-judge"
                    ? "2px solid black"
                    : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "the-judge"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "the-judge"}
                  readOnly
                  onChange={() => {
                    setdoor("the-judge");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/the-judge.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/the-judge.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "the-judge" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                The Judge
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("prison");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/prison.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "prison" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "prison"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "prison"}
                  readOnly
                  onChange={() => {
                    setdoor("prison");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/prison.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/prison.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "prison" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Prison
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("curved");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/curved.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "curved" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "curved"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "curved"}
                  readOnly
                  onChange={() => {
                    setdoor("curved");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/curved.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/curved.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "curved" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Curved
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("lindsey");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/lindsey.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "lindsey" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "lindsey"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "lindsey"}
                  readOnly
                  onChange={() => {
                    setdoor("lindsey");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/lindsey.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/lindsey.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "lindsey" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Lindsey
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("baku");
              setCompositionImage(
                "https://config.livingsteel.nl/images/doortypes/baku.png"
              );
            }}
          >
            <div
              style={{
                border:
                  door === "baku" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "baku"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "baku"}
                  readOnly
                  onChange={() => {
                    setdoor("baku");
                    setCompositionImage(
                      "https://config.livingsteel.nl/images/doortypes/baku.png"
                    );
                  }}
                />
              </div>
              <img
                src="./images/doortypes/baku.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "baku" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Baku
              </span>
            </div>
          </div>
          <div style={{ position: "relative", fontSize: "small" }}>
            <div
              style={{
                border:
                  door === "custom" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                aspectRatio: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow:
                  door === "custom"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "custom"}
                  readOnly
                />
              </div>
              {attachDesign ? (
                <span
                  style={{
                    display: "block",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: 700,
                    fontFamily: "system-ui",
                    wordBreak: "break-all",
                  }}
                >
                  {attachDesign.name}
                </span>
              ) : (
                <span
                  style={{
                    display: "block",
                    textAlign: "center",
                    fontSize: "54px",
                    fontWeight: 700,
                    fontFamily: "system-ui",
                  }}
                >
                  ?
                </span>
              )}
              <input
                type="file"
                name="file"
                id="file"
                onChange={handleChange}
                style={{
                  opacity: 0,
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "custom" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Eigen design
              </span>
            </div>
          </div>
          {/* <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("larino");
            }}
          >
            <div
              style={{
                border:
                  door === "larino" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "larino"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "larino"}
                  readOnly
                  onChange={() => setdoor("larino")}
                />
              </div>
              <img
                src="./images/doortypes/larino.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "larino" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Larino
              </span>
            </div>
          </div> */}
          {/* <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("lucas");
            }}
          >
            <div
              style={{
                border:
                  door === "lucas" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "lucas"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "lucas"}
                  readOnly
                  onChange={() => setdoor("lucas")}
                />
              </div>
              <img
                src="./images/doortypes/lucas.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "lucas" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Lucas
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("herman");
            }}
          >
            <div
              style={{
                border:
                  door === "herman" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "herman"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "herman"}
                  readOnly
                  onChange={() => setdoor("herman")}
                />
              </div>
              <img
                src="./images/doortypes/herman.jpg"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "herman" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Herman
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("dt3");
            }}
          >
            <div
              style={{
                border:
                  door === "dt3" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "dt3"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "dt3"}
                  readOnly
                  onChange={() => setdoor("dt3")}
                />
              </div>
              <img
                src="./images/doortypes/dt3.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "dt3" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                4 panelen
              </span>
            </div>
          </div> */}

          {/* <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("80s");
            }}
          >
            <div
              style={{
                border:
                  door === "80s" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "80s"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "80s"}
                  readOnly
                  onChange={() => setdoor("80s")}
                />
              </div>
              <img
                src="./images/doortypes/80s.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "80s" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                80’s
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("rembrandt");
            }}
          >
            <div
              style={{
                border:
                  door === "rembrandt"
                    ? "2px solid black"
                    : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "rembrandt"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "rembrandt"}
                  readOnly
                  onChange={() => setdoor("rembrandt")}
                />
              </div>
              <img
                src="./images/doortypes/rembrandt.jpg"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "rembrandt" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Rembrandt
              </span>
            </div>
          </div>
          
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("drentse");
            }}
          >
            <div
              style={{
                border:
                  door === "drentse" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "drentse"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "drentse"}
                  readOnly
                  onChange={() => setdoor("drentse")}
                />
              </div>
              <img
                src="./images/doortypes/drentse.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "drentse" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Drentse stijl
              </span>
            </div>
          </div> */}

          {/* <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("elody");
            }}
          >
            <div
              style={{
                border:
                  door === "elody" ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "elody"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "elody"}
                  readOnly
                  onChange={() => setdoor("elody")}
                />
              </div>
              <img
                src="./images/doortypes/elody.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "elody" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Elody
              </span>
            </div>
          </div>
          
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setdoor("staldeuren");
            }}
          >
            <div
              style={{
                border:
                  door === "staldeuren"
                    ? "2px solid black"
                    : "2px solid #d7d7d7",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow:
                  door === "staldeuren"
                    ? "none"
                    : "rgba(0, 0, 0, 0.6) -5px -2px 10px -2px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={door == "staldeuren"}
                  readOnly
                  onChange={() => setdoor("staldeuren")}
                />
              </div>
              <img
                src="./images/doortypes/staldeuren.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: door === "staldeuren" ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Staldeuren
              </span>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div style={{ width: '100%' }}>
        <div
          style={{
            margin: '5%',
            paddingTop: '0.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 'bolder',
            fontSize: 'normal',
          }}
        >
          <span>Profielmaat</span>
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '20px',
              width: 'auto',
              padding: '2px 10px',
              margin: '0 0 0 10px',
            }}
          >
            <span
              className="body-txt"
              style={{}}
              onClick={() => {
                setStep('door');
              }}
            >
              &euro; 0,00
            </span>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          className="door-content-2-1"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateRows: 'repeat(1, 1fr)',
            gridColumnGap: '10px',
            gridRowGap: '10px',
            margin: '5%',
            maxWidth: '80%',
          }}
        >
          <div
            style={{ position: 'relative', fontSize: 'small' }}
            onClick={() => {
              setFrameSize(0.25);
            }}
          >
            <div
              style={{
                border: frameSize === 0.25 ? '2px solid black' : '2px solid #d7d7d7',
                borderRadius: '5px',
                overflow: 'hidden'
              }}
            >
              <div style={{ position: 'absolute', top: '2px', right: '2px' }}>
                <input
                  type="radio"
                  className="sm"
                  checked={frameSize == 0.25}
                  readOnly
                  onChange={() => setFrameSize(0.25)}
                />
              </div>
              <img
                src="./images/doortypes/40mm.png"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </div>
            <div
              style={{
                margin: '10px 0 0 0',
                backgroundColor: door === 0.25 ? 'black' : '#bdc79d',
                color: 'white',
                position: 'relative',
                paddingTop: '5px',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '-5px',
                  left: '0',
                  width: '100%',
                  height: '8px',
                  borderRadius: '50px',
                  backgroundColor: '#ffffff',
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: 'block',
                  wordWrap: 'break-word',
                  textAlign: 'center',
                }}
              >
                40mm
              </span>
            </div>
          </div>
          <div
            style={{ position: 'relative', fontSize: 'small' }}
            onClick={() => {
              setFrameSize(0.2);
            }}
          >
            <div
              style={{
                border: frameSize === 0.2 ? '2px solid black' : '2px solid #d7d7d7',
                borderRadius: '5px',
              }}
            >
              <div style={{ position: 'absolute', top: '2px', right: '2px' }}>
                <input
                  type="radio"
                  className="sm"
                  checked={frameSize == 0.2}
                  readOnly
                  onChange={() => setFrameSize(0.2)}
                />
              </div>
              <img
                src="./images/doortypes/30mm.png"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </div>
            <div
              style={{
                margin: '10px 0 0 0',
                backgroundColor: door === 0.2 ? 'black' : '#bdc79d',
                color: 'white',
                position: 'relative',
                paddingTop: '5px',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '-5px',
                  left: '0',
                  width: '100%',
                  height: '8px',
                  borderRadius: '50px',
                  backgroundColor: '#ffffff',
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: 'block',
                  wordWrap: 'break-word',
                  textAlign: 'center',
                }}
              >
                30mm
              </span>
            </div>
          </div>
          <div
            style={{ position: 'relative', fontSize: 'small' }}
            onClick={() => {
              setFrameSize(0.15);
            }}
          >
            <div
              style={{
                border: frameSize === 0.15 ? '2px solid black' : '2px solid #d7d7d7',
                borderRadius: '5px',
              }}
            >
              <div style={{ position: 'absolute', top: '2px', right: '2px' }}>
                <input
                  type="radio"
                  className="sm"
                  checked={frameSize == 0.15}
                  readOnly
                  onChange={() => setFrameSize(0.15)}
                />
              </div>
              <img
                src="./images/doortypes/20mm.png"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </div>
            <div
              style={{
                margin: '10px 0 0 0',
                backgroundColor: door === 0.15 ? 'black' : '#bdc79d',
                color: 'white',
                position: 'relative',
                paddingTop: '5px',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '-5px',
                  left: '0',
                  width: '100%',
                  height: '8px',
                  borderRadius: '50px',
                  backgroundColor: '#ffffff',
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: 'block',
                  wordWrap: 'break-word',
                  textAlign: 'center',
                }}
              >
                20mm
              </span>
            </div>
          </div>
        </div>
      </div> */}
      <div style={{ width: "100%" }}>
        <div
          style={{
            margin: "5%",
            paddingTop: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "bolder",
            fontSize: "normal",
          }}
        >
          <InfoIcon title={"Handgreep"} />
          {/* <div
            style={{
              backgroundColor: "white",
              borderRadius: "20px",
              width: "auto",
              padding: "2px 10px",
              margin: "0 0 0 10px",
            }}
          >
            <span
              className="body-txt"
              style={{}}
              onClick={() => {
                setStep("door");
              }}
            >
              &euro; 0,00
            </span>
          </div> */}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderBottom: "1px solid #d1d1d1",
          paddingBottom: "15px",
        }}
      >
        <div
          className="door-content-2-1"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridTemplateRows: "repeat(2, 1fr)",
            gridColumnGap: "10px",
            gridRowGap: "10px",
            margin: "5%",
            marginTop: "0",
            maxWidth: "90%",
          }}
        >
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setHandle(1);
            }}
          >
            <div
              style={{
                border: handle === 1 ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={handle == 1}
                  readOnly
                  onChange={() => setHandle(1)}
                />
              </div>
              <img
                src="./images/doortypes/hoeklijn.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              className="handle-title-box"
              style={{
                backgroundColor: handle === 1 ? "black" : "#bdc79d",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#ffffff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Hoeklijn
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setHandle(2);
            }}
          >
            <div
              style={{
                border: handle === 2 ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={handle == 2}
                  readOnly
                  onChange={() => setHandle(2)}
                />
              </div>
              <img
                src="./images/doortypes/hoeklijn_L.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              className="handle-title-box"
              style={{
                backgroundColor: handle === 2 ? "black" : "#bdc79d",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#ffffff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Beugel
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setHandle(3);
            }}
          >
            <div
              style={{
                border: handle === 3 ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={handle == 3}
                  readOnly
                  onChange={() => setHandle(3)}
                />
              </div>
              <img
                src="./images/doortypes/koker_s.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              className="handle-title-box"
              style={{
                backgroundColor: handle === 3 ? "black" : "#bdc79d",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#ffffff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Circle M
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setHandle(4);
            }}
          >
            <div
              style={{
                border: handle === 4 ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={handle == 4}
                  readOnly
                  onChange={() => setHandle(4)}
                />
              </div>
              <img
                src="./images/doortypes/koker_l.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              className="handle-title-box"
              style={{
                backgroundColor: handle === 4 ? "black" : "#bdc79d",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#ffffff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Circle L
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setHandle(5);
            }}
          >
            <div
              style={{
                border: handle === 5 ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={handle == 5}
                  readOnly
                  onChange={() => setHandle(5)}
                />
              </div>
              <img
                src="./images/doortypes/hondla.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              className="handle-title-box"
              style={{
                backgroundColor: handle === 5 ? "black" : "#bdc79d",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#ffffff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Koker M
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setHandle(6);
            }}
          >
            <div
              style={{
                border: handle === 6 ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={handle == 6}
                  readOnly
                  onChange={() => setHandle(6)}
                />
              </div>
              <img
                src="./images/doortypes/hondla_L.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              className="handle-title-box"
              style={{
                backgroundColor: handle === 6 ? "black" : "#bdc79d",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#ffffff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Koker L
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setHandle(7);
            }}
          >
            <div
              style={{
                border: handle === 7 ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={handle == 7}
                  readOnly
                  onChange={() => setHandle(7)}
                />
              </div>
              <img
                src="./images/doortypes/SleeveM.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              className="handle-title-box"
              style={{
                backgroundColor: handle === 7 ? "black" : "#bdc79d",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#ffffff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Greeploos
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setHandle(8);
            }}
          >
            <div
              style={{
                border: handle === 8 ? "2px solid black" : "2px solid #d7d7d7",
                borderRadius: "5px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={handle == 8}
                  readOnly
                  onChange={() => setHandle(8)}
                />
              </div>
              <img
                src="./images/doortypes/SleeveL.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div
              className="handle-title-box"
              style={{
                backgroundColor: handle === 8 ? "black" : "#bdc79d",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#ffffff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                  lineHeight: 1.1,
                  paddingTop: "5px",
                }}
              >
                Handgreep met leer
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            marginLeft: "5%",
            marginTop: "5%",
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bolder",
            fontSize: "normal",
          }}
        >
          <InfoIcon title={"Glas soort"} />
          {/* <div
            style={{
              backgroundColor: "white",
              borderRadius: "20px",
              width: "auto",
              padding: "2px 10px",
              margin: "0 0 0 10px",
            }}
          >
            <span
              className="body-txt"
              style={{}}
              onClick={() => {
                setStep("door");
              }}
            >
              &euro; 400,00
            </span>
          </div> */}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderBottom: "1px solid #d1d1d1",
          paddingBottom: "15px",
        }}
      >
        <div
          className="door-content-2-1"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridTemplateRows: "1fr",
            gridColumnGap: "10px",
            gridRowGap: "10px",
            margin: "5%",
            maxWidth: "90%",
          }}
        >
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setGlassType(0x111111);
            }}
          >
            <div
              style={{
                border:
                  glassType === 0x111111
                    ? "2px solid black"
                    : "2px solid #d7d7d7",
                borderRadius: "5px",
                height: "105px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={glassType == 0x111111}
                  readOnly
                  onChange={() => setGlassType(0x111111)}
                />
              </div>
              <img
                src="./images/doortypes/glas_soort_1.jpg"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: glassType === 0x111111 ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Helderglas
              </span>
            </div>
          </div>
          {/* <div
            style={{ position: 'relative', fontSize: 'small' }}
            onClick={() => {
              setGlassType(0xfaf7f0);
            }}
          >
            <div
              style={{
                border: glassType === 0xfaf7f0 ? '2px solid black' : '2px solid #d7d7d7',
                borderRadius: '5px',
              }}
            >
              <div style={{ position: 'absolute', top: '2px', right: '2px' }}>
                <input
                  type="radio"
                  className="sm"
                  checked={glassType == 0xfaf7f0}
                  readOnly
                  onChange={() => setGlassType(0xfaf7f0)}
                />
              </div>
              <img
                src="./images/doortypes/glas_melk.jpg"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </div>
            <div
              style={{
                margin: '10px 0 0 0',
                backgroundColor: glassType === 0xfaf7f0 ? 'black' : '#bdc79d',
                color: 'white',
                position: 'relative',
                paddingTop: '5px',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '-5px',
                  left: '0',
                  width: '100%',
                  height: '8px',
                  borderRadius: '50px',
                  backgroundColor: '#ffffff',
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: 'block',
                  wordWrap: 'break-word',
                  textAlign: 'center',
                }}
              >
                Melkglas
              </span>
            </div>
          </div> */}
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setGlassType(0x060909);
            }}
          >
            <div
              style={{
                border:
                  glassType === 0x060909
                    ? "2px solid black"
                    : "2px solid #d7d7d7",
                borderRadius: "5px",
                height: "105px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={glassType == 0x060909}
                  readOnly
                  onChange={() => setGlassType(0x060909)}
                />
              </div>
              <img
                src="./images/doortypes/glas_soort_2.jpg"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: glassType === 0x060909 ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Rookglas
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setGlassType(0x98795d);
            }}
          >
            <div
              style={{
                border:
                  glassType === 0x98795d
                    ? "2px solid black"
                    : "2px solid #d7d7d7",
                borderRadius: "5px",
                height: "105px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={glassType == 0x98795d}
                  readOnly
                  onChange={() => setGlassType(0x98795d)}
                />
              </div>
              <img
                src="./images/doortypes/glas_soort_3.jpg"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: glassType === 0x98795d ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                bronze
              </span>
            </div>
          </div>
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setGlassType(0xffffff);
            }}
          >
            <div
              style={{
                border:
                  glassType === 0xffffff
                    ? "2px solid black"
                    : "2px solid #d7d7d7",
                borderRadius: "5px",
                height: "105px",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={glassType == 0xffffff}
                  readOnly
                  onChange={() => setGlassType(0xffffff)}
                />
              </div>
              <img
                src="./images/doortypes/glas_soort_4.png"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                margin: "10px 0 0 0",
                backgroundColor: glassType === 0xffffff ? "black" : "#bdc79d",
                color: "white",
                position: "relative",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
              >
                Melkglas
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            marginLeft: "5%",
            marginTop: "5%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "bolder",
            fontSize: "normal",
          }}
        >
          <InfoIcon title={"Kleur"} />
          {/* <div
            style={{
              backgroundColor: "white",
              borderRadius: "20px",
              width: "auto",
              padding: "2px 10px",
              margin: "0 0 0 10px",
            }}
          >
            <span
              className="body-txt"
              style={{}}
              onClick={() => {
                setStep("door");
              }}
            >
              &euro; 400,00
            </span>
          </div> */}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="door-content-2-1"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridTemplateRows: "repeat(3, minmax(0, 1fr))",
            gridColumnGap: "10px",
            gridRowGap: "10px",
            margin: "5%",
            maxWidth: "90%",
            position: "relative",
          }}
        >
          {stalKleur.map((stal, idx) => (
            <div
              key={`stal_${idx}`}
              style={{ position: "relative", fontSize: "small" }}
              onClick={() => {
                setFrameType(stal.imgUrl);
                setCustomFrameType({ name: "", color: "" });
              }}
            >
              <div
                style={{
                  border:
                    frameType === stal.imgUrl
                      ? "2px solid black"
                      : "2px solid transparent",
                  borderRadius: "5px",
                  height: "120px",
                  overflow: "hidden",
                }}
              >
                <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                  <input
                    type="radio"
                    className="sm"
                    checked={frameType == stal.imgUrl}
                    readOnly
                    onChange={() => {
                      setFrameType(stal.imgUrl);
                      setCustomFrameType({ name: "", color: "" });
                    }}
                  />
                </div>
                <img
                  src={stal.imgUrl}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                style={{
                  margin: "7px 0 0 0",
                  backgroundColor:
                    frameType === stal.imgUrl ? "black" : "#bdc79d",
                  color: "white",
                  position: "relative",
                  padding: "5px 5px 0",
                  height: "42px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-5px",
                    left: "0",
                    width: "100%",
                    height: "8px",
                    borderRadius: "50px",
                    backgroundColor: "#fff",
                  }}
                />
                <span
                  className="itm-txt"
                  style={{
                    display: "block",
                    wordWrap: "break-word",
                    textAlign: "center",
                    lineHeight: 1.2,
                  }}
                >
                  {stal.title}
                </span>
              </div>
            </div>
          ))}
          <div
            style={{ position: "relative", fontSize: "small" }}
            onClick={() => {
              setColorPickerOpened(true);
            }}
          >
            <div
              style={{
                border: "2px solid transparent",
                borderRadius: "5px",
                height: "120px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <input
                  type="radio"
                  className="sm"
                  checked={false}
                  readOnly
                  // onChange={() => setFrameType(stal.imgUrl)}
                />
              </div>
              <span
                style={{
                  position: "absolute",
                  left: 0,
                  top: "50%",
                  width: "100%",
                  textAlign: "center",
                  transform: "translateY(-50%)",
                }}
              >
                Kies RAL kleur
              </span>
              {customFrameType.name !== "" && (
                <img
                  src={frameType}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
            </div>
            <div
              style={{
                margin: "7px 0 0 0",
                backgroundColor: "#bdc79d",
                color: "white",
                position: "relative",
                padding: "5px 5px 0",
                height: "42px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "0",
                  width: "100%",
                  height: "8px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                }}
              />
              <span
                className="itm-txt"
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  textAlign: "center",
                  lineHeight: 1.2,
                }}
              >
                Custom
              </span>
            </div>
          </div>
          <ColorSelector />
        </div>
      </div>
      <button
        className="volgende btn"
        onClick={() => {
          setStep("extra");
        }}
      >
        Volgende
      </button>
    </>
  );
}
