import App from './App'
import './style.css'
import './style0.css'
import './tailwind.css'
import ReactDOM from 'react-dom/client'
import "@fontsource/poppins"; 

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <>
        <App />
    </>
)