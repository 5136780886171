import { MyContext } from './data/contextapi';
import { useContext } from 'react';
import Structure from './Structure';

export default function Stalen() {
  const { width, height, stalenPart, stalenType } = useContext(MyContext);

  const doorWidth = (0.06 * width) / stalenPart;
  const doorHeightn = 0.053 * height;

  return (
    <>
      {stalenType == 'tussen' ? (
        <>
          {stalenPart >= 1 && (
            <group
              position={[
                (doorWidth / 2 -
                  (stalenPart == 2
                    ? 0.075
                    : stalenPart == 3
                    ? 0.15
                    : stalenPart == 4
                    ? -0.7
                    : 0.15)) *
                  (stalenPart == 2
                    ? 2
                    : stalenPart == 3
                    ? 1
                    : stalenPart == 4
                    ? -0.1
                    : 1),
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
            >
              <group position={[-doorWidth / 2, 0, 0]}>
                <Structure sizePannel={0} />
              </group>
            </group>
          )}
          {stalenPart >= 2 && (
            <group
              position={[
                (doorWidth / 2 -
                  (stalenPart == 3
                    ? -0.05
                    : stalenPart == 4
                    ? -0.02
                    : -0.075)) *
                  (stalenPart == 3 ? -3 : stalenPart == 4 ? -4.1 : -2),
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
              rotation={[0, Math.PI, 0]}
            >
              <group
                position={[-doorWidth / 2, 0, 0]}
                rotation={[0, Math.PI, 0]}
              >
                <Structure sizePannel={0} />
              </group>
            </group>
          )}
          {stalenPart >= 3 && (
            <group
              position={[
                (doorWidth / 2 - (stalenPart == 4 ? 0.035 : 0.05)) *
                  (stalenPart == 4 ? 1.9 : 3),
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
            >
              <group position={[-doorWidth / 2, 0, 0]}>
                <Structure sizePannel={0} />
              </group>
            </group>
          )}
          {stalenPart >= 4 && (
            <group
              position={[
                (doorWidth / 2 - 0.02) * 3.9,
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
            >
              <group position={[-doorWidth / 2, 0, 0]}>
                <Structure sizePannel={0} />
              </group>
            </group>
          )}
        </>
      ) : (
        <>
          {stalenPart >= 1 && (
            <group
              position={[
                0.06 * 105 - doorWidth - 0.15,
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
              rotation={[0, Math.PI, 0]}
            >
              <group
                position={[-doorWidth / 2, 0, 0]}
                rotation={[0, Math.PI, 0]}
              >
                <Structure sizePannel={0} />
              </group>
            </group>
          )}
          {stalenPart >= 2 && (
            <group
              position={[
                0.06 * 105 - doorWidth * 2 - 0.15,
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
              rotation={[0, Math.PI, 0]}
            >
              <group
                position={[-doorWidth / 2, 0, 0]}
                rotation={[0, Math.PI, 0]}
              >
                <Structure sizePannel={0} />
              </group>
            </group>
          )}
          {stalenPart >= 3 && (
            <group
              position={[
                0.06 * 105 - doorWidth * 3 - 0.15,
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
              rotation={[0, Math.PI, 0]}
            >
              <group
                position={[-doorWidth / 2, 0, 0]}
                rotation={[0, Math.PI, 0]}
              >
                <Structure sizePannel={0} />
              </group>
            </group>
          )}
          {stalenPart >= 4 && (
            <group
              position={[
                0.06 * 105 - doorWidth * 4 - 0.15,
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
              rotation={[0, Math.PI, 0]}
            >
              <group
                position={[-doorWidth / 2, 0, 0]}
                rotation={[0, Math.PI, 0]}
              >
                <Structure sizePannel={0} />
              </group>
            </group>
          )}
        </>
      )}
    </>
  );
}
